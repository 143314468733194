<template>
    <div class="hello">
        <div class="screenUse">
          <Nav-bar style="width: 100%;"/>
          <vs-row>
            <vs-col vs-w="12" style="overflow:scroll; height: calc(100vh - 48px);">
              <div style="position: fixed; width: 100%; z-index: 1000;">
                <vs-card class="navbar" style="margin-bottom: -10px; border-bottom: 3px solid #f0c1c5;">
                <div style="width: 100%; display: inline-block; margin-bottom: -15px; color: black; font-size: 20px;">
                  <span style="color: #C32D39;"><vs-icon icon="home" size="" style="font-size: 18px;"></vs-icon> DOSSIERS /</span> IMPORT
                  <span style="float: right; margin-right: 150px;">
                    <vs-button color="#C32D39"  icon="add" style="padding: 5px; position: absolute; width: 150px;" @click="popupActivo2=true">Créer un ordre</vs-button>
                </span>
                  <el-date-picker
                      v-model="dateSearch"
                      type="daterange"
                      range-separator="à"
                      start-placeholder="Date de début"
                      end-placeholder="Date de fin">
                    </el-date-picker>
                    <el-button icon="el-icon-search" circle @click="dateCherche()"></el-button>
                </div>
                <span style="float: right; margin-right: 150px;">
                  
                </span>   
                </vs-card>
              </div>
              
              <div style="padding: 10px 20px; font-family:'Karla', sans-serif; margin-top: 70px;">
  
                  <vs-divider position="left" style="width: 90%; margin: 20px auto">BILAN VOYAGES <b>BENNE</b></vs-divider>
  
  
  
                    <table border="1" ref="exportable_table" v-show="false">
                      <tbody >
                      <tr>
                          <th>ZONE</th>
                          <th>MODE</th>
                          <th>TYPE</th>
                          <th>NOMBRE</th>
                      </tr>
                      <tr v-for="(item, i) in donneesZones" :key="i">
                        <td >{{item.zone_facturation}}</td>
                          <td>{{item.mode}}</td>
                          <td>{{item.type_c}}</td>
                          <td>{{item.nombre}}</td>
                      </tr>
                      </tbody>
                  </table>
  
                  <div style="background-color: white;">
                    <vs-row>
                      
                      <vs-col vs-w="12" style="display: grid;">
                        <vs-card style="font-size: 12px; width: 90%; margin: 0 auto; border-radius: 20px; padding: 15px">
                          <vs-table stripe search max-items="20" pagination  :data="donneesDossiers" style="font-size: 12px">
                            <template slot="header">
                                <h3>LISTE DES BONS <el-link type="success" @click="ExportExcel('xls')"><img src="../../assets/excel.png" width="14"> EXPORTER</el-link> </h3>
                                <span style="margin-left: 25px; cursor: pointer" @click="popupActivo4=true"></span>
                            </template>
                            <template slot="thead">
                              <vs-th>
                                Date
                              </vs-th>
                              <vs-th>
                                Bon
                              </vs-th>
                              <vs-th>
                                Client/Produit
                              </vs-th>
                              <vs-th>
                                Charge/Decharge
                              </vs-th>
                              <vs-th>
                                Chauffeur
                              </vs-th>
                              <vs-th>
                                Camion
                              </vs-th>
                              <vs-th>
                                Poids
                              </vs-th>
                              <vs-th>
                                Action
                              </vs-th>
                              
                            </template>
          
                            <template slot-scope="{data}">
                              <vs-tr :state="data[indextr].dte_voyage.length >= 1000?'success':data[indextr].dte_voyage == 3 && data[indextr].dte_voyage != data[indextr].dte_voyage ?'danger':null" :key="indextr" v-for="(tr, indextr) in data">
                                <vs-td :data="data[indextr].dte_voyage">
                                  <span style="color: blue "  @click="ChangeStatut()">{{data[indextr].dte_voyage}}</span>
                                </vs-td>
          
                                <vs-td style="font-size: 12px;" :data="data[indextr].username">
                                  <span style="font-weight: bold;"  @click="ChangeStatut()">{{data[indextr].bon}}</span>
                                </vs-td>
          
                                <vs-td style="font-size: 12px" :data="data[indextr].username">
                                  <vs-chip transparent color="primary">
                                    <b style="font-size: 11px; color: blue;">{{ data[indextr].client }}</b>-<b style="color: black; font-weight: normal">{{ data[indextr].produit }}</b>
                                  </vs-chip>
                                  
                                </vs-td>
          
                                <vs-td style="font-size: 12px;" :data="data[indextr].type_c">
                                  <span style="color: #8708088f">{{data[indextr].chargement}}</span> / {{data[indextr].dechargement}}
                                </vs-td>
          
                                <vs-td style="font-size: 12px; font-weight: bold;" :data="data[indextr].type_c">
                                  
                                  <vs-chip transparent color="dark">
                                    <vs-avatar />
                                    {{data[indextr].chauffeur}}
                                  </vs-chip>
                                </vs-td>
                                <vs-td style="font-size: 12px; font-weight: bold;" :data="data[indextr].type_c">
                                  <vs-icon style="font-size: 12px" icon="local_shipping"></vs-icon> {{data[indextr].camion}}
                                </vs-td>

                                <vs-td style="font-size: 14px; font-weight: bold; color: blue;" :data="data[indextr].type_c">
                                  {{data[indextr].poids}} T
                                </vs-td>

                                <vs-td style="font-size: 12px" :data="data[indextr].type_c">
                                  <el-button type="primary" icon="el-icon-edit" circle  @click="Modif(data[indextr])"></el-button>
                                  <el-button type="danger" icon="el-icon-delete" circle  @click="supBon(data[indextr].id_bon, data[indextr].camion, data[indextr].bon)"></el-button>
                                </vs-td>
                              </vs-tr>
                            </template>
                          </vs-table>
                        </vs-card>
                      </vs-col>
                    </vs-row>
                  </div>
                  
  
                
                <vs-divider/>
                
              </div>
  
            </vs-col>
          </vs-row>
  
          <vs-popup classContent="popup-example"  title="Nouveau bon" :active.sync="popupActivo2">
            <div>
              <vs-row>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Client</label>
                  <vs-select
                    style="width: 100%;"
                    v-model="form.client"
                    >
                    <vs-select-item :key="index" :value="item.value" :text="item.label" v-for="item,index in clients" />
                  </vs-select>
                </vs-col>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Produit</label>
                  <vs-select
                    style="width: 100%;"
                    v-model="form.produit"
                    >
                    <vs-select-item :key="index" :value="item.value" :text="item.label" v-for="item,index in produits" />
                  </vs-select>
                </vs-col>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>N° Bon</label>
                  <el-input v-model="form.bon" placeholder="N° bon">
                  </el-input>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Date Voyage</label>
                  <el-input   type="date" v-model="form.dte_voyage" placeholder="Date voyage">
                  </el-input>
                </vs-col>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Camion</label>
                  <vs-select
                    style="width: 100%;"
                    placeholder="Camion"
                    class="selectExample"
                    label-placeholder="Autocomplete"
                    autocomplete
                    v-model="form.camion"
                    >
                    <vs-select-item :key="index" :value="item.matricule" :text="item.matricule" v-for="item,index in donneesCamion" />
                  </vs-select>
                </vs-col>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Chauffeur</label>
                  <vs-select
                    style="width: 100%;"
                    placeholder="Chauffeur"
                    class="selectExample"
                    label-placeholder="Autocomplete"
                    autocomplete
                    v-model="form.chauffeur"
                    >
                    <vs-select-item :key="index" :value="item.nom +' '+item.prenom" :text="item.nom +' '+item.prenom" v-for="item,index in donneesChauf" />
                  </vs-select>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Poids</label><br/>
                  <el-input-number width="100%" v-model="form.poids" :precision="2" :step="0.1" :max="10"></el-input-number>
                </vs-col>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Chargement</label>
                  <el-input style="width: 100%;" v-model="form.chargement" placeholder="Lieu de chargement">
                  </el-input>
                </vs-col>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Déchargement</label>
                  <el-input style="width: 100%;" v-model="form.dechargement" placeholder="Lieu de déchargement">
                  </el-input>
                </vs-col>
              </vs-row>
              
            </div>
           
            
            <center><vs-button style="margin: 20px; width: 300px;" @click="onSubmit()" color="primary" type="filled">Enregistrer le bon</vs-button></center>
            <vs-popup title="Inner popup" :active.sync="popupActivo3">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                <br><br>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                <br><br>
                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              </vs-popup>
          </vs-popup>

          <vs-popup classContent="popup-example"  title="Modif bon" :active.sync="popupActivo22">
            <div>
              <vs-row>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Client</label>
                  <vs-select
                    style="width: 100%;"
                    v-model="form.client"
                    >
                    <vs-select-item :key="index" :value="item.value" :text="item.label" v-for="item,index in clients" />
                  </vs-select>
                </vs-col>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Produit</label>
                  <vs-select
                    style="width: 100%;"
                    v-model="form.produit"
                    >
                    <vs-select-item :key="index" :value="item.value" :text="item.label" v-for="item,index in produits" />
                  </vs-select>
                </vs-col>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>N° Bon</label>
                  <el-input v-model="form.bon" placeholder="N° bon">
                  </el-input>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Date Voyage</label>
                  <el-input   type="date" v-model="form.dte_voyage" placeholder="Date voyage">
                  </el-input>
                </vs-col>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Camion</label>
                  <vs-select
                    style="width: 100%;"
                    placeholder="Camion"
                    class="selectExample"
                    label-placeholder="Autocomplete"
                    autocomplete
                    v-model="form.camion"
                    >
                    <vs-select-item :key="index" :value="item.matricule" :text="item.matricule" v-for="item,index in donneesCamion" />
                  </vs-select>
                </vs-col>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Chauffeur</label>
                  <vs-select
                    style="width: 100%;"
                    placeholder="Chauffeur"
                    class="selectExample"
                    label-placeholder="Autocomplete"
                    autocomplete
                    v-model="form.chauffeur"
                    >
                    <vs-select-item :key="index" :value="item.nom +' '+item.prenom" :text="item.nom +' '+item.prenom" v-for="item,index in donneesChauf" />
                  </vs-select>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Poids</label><br/>
                  <el-input-number width="100%" v-model="form.poids" :precision="2" :step="0.1" :max="10"></el-input-number>
                </vs-col>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Chargement</label>
                  <el-input style="width: 100%;" v-model="form.chargement" placeholder="Lieu de chargement">
                  </el-input>
                </vs-col>
                <vs-col style="padding: 5px;" vs-justify="center" vs-align="center" vs-w="4">
                  <label>Déchargement</label>
                  <el-input style="width: 100%;" v-model="form.dechargement" placeholder="Lieu de déchargement">
                  </el-input>
                </vs-col>
              </vs-row>
              
            </div>
           
            
            <center><vs-button style="margin: 20px; width: 300px;" @click="onUpdate()" color="primary" type="filled">Modifier le bon</vs-button></center>
            <vs-popup title="Inner popup" :active.sync="popupActivo3">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                <br><br>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                <br><br>
                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              </vs-popup>
          </vs-popup>
  
          <vs-popup classContent="popup-example"  title="Bordereau de livraison" :active.sync="activePrompt9">
            
            <center style="width: 21cm;">
              
              <vue-html2pdf
                  :show-layout="false"
                  :float-layout="true"
                  :enable-download="true"
                  :preview-modal="true"
                  :paginate-elements-by-height="1100"
                  filename="hee hee"
                  :pdf-quality="2"
                  :manual-pagination="false"
                  pdf-format="a4"
                  pdf-orientation="portrait"
                  pdf-content-width="800px"
    
                  @progress="onProgress($event)"
                  @hasStartedGeneration="hasStartedGeneration()"
                  @hasGenerated="hasGenerated($event)"
                  ref="html2Pdf"
              >
                  <section slot="pdf-content">
                    <div style="width: 19.5cm; margin-left: .5cm; font-size: 12px; margin-top: 20px" id="html-to-pdf" ref="content">
                      <div style="width: 100%; padding: 10px">
                        <div style="float: left; display: block;">
                          <img :src="users.logo" width="90">
                        </div>
                        <div style="float: left; padding: 12px;">
                          <p style="font-weight: bold; font-size: 20px; font-family: 'Nunito Sans', sans-serif; text-align: center">{{users.entreprise_long}}</p>
                        </div>
                      </div>
                      .
                      <div>
                        <vs-divider style="font-family: 'Nunito Sans', sans-serif;"></vs-divider>
                      </div>
  
                      <div>
                        <p style="font-family: 'Nunito Sans', sans-serif; font-size: 42px; text-align: center; font-weight: bold; background-color: gainsboro; color: darkgoldenrod; font-family:Verdana, Geneva, Tahoma, sans-serif;">{{ elementChoisi.n_doc }}</p>
                      </div>
                      <div>
                        <p style="font-family: 'Nunito Sans', sans-serif; font-size: 90px; text-align: center; font-weight: bold; background-color: gainsboro; color:midnightblue; font-family:'Times New Roman', Times, serif">{{ elementChoisi.armateur }}</p>
                      </div>
                      <div>
                        <p style="font-family: 'Nunito Sans', sans-serif; padding: 25px; font-size: 35px; text-align: center; font-weight: bold; background-color: rgb(237, 249, 253); color: gray; font-family:Verdana, Geneva, Tahoma, sans-serif;">{{ elementChoisi.client }}</p>
                      </div>
  
                      <vs-divider style="font-family: 'Nunito Sans', sans-serif;"></vs-divider>
    
                      <div style="text-align: left; font-family: 'Nunito Sans', sans-serif;" v-if="elementChoisi != null">
                        <vs-row style="width: 75%; margin: 0 auto;">
                          <vs-col vs-w="12" style="padding: 5px; font-size: 25px;">
                            <el-input placeholder="" v-model="elementChoisi.date_recept" class="custom-input1">
                              <template slot="prepend"><span class="custom-input2">DATE RECEPTION</span></template>
                            </el-input>
                          </vs-col>
                          <vs-col vs-w="12" style="padding: 5px; font-size: 15px;">
                            <el-input placeholder="" v-model="elementChoisi.transitaire" class="custom-input1">
                              <template slot="prepend"><span class="custom-input2">TRANSITAIRE</span></template>
                            </el-input>
                          </vs-col>
                          <vs-col vs-w="12" style="padding: 5px; font-size: 15px;" v-if="elementChoisi.mode == 'AC'">
                            <el-input placeholder="" value="AUTO CHARGEUSE" class="custom-input1">
                              <template slot="prepend"><span class="custom-input2">MODE LIVRAISON</span></template>
                            </el-input>
                          </vs-col>
                          <vs-col vs-w="12" style="padding: 5px; font-size: 15px;" v-if="elementChoisi.mode == 'REM'">
                            <el-input placeholder="" value="REMORQUE" class="custom-input1">
                              <template slot="prepend"><span class="custom-input2">MODE LIVRAISON</span></template>
                            </el-input>
                          </vs-col>
                          <vs-col vs-w="12" style="padding: 5px; font-size: 15px;">
                            <el-input placeholder="" v-model="elementChoisi.zone_livraison" class="custom-input1">
                              <template slot="prepend"><span class="custom-input2">ZONE DE LIVRAISON</span></template>
                            </el-input>
                          </vs-col>
                          <vs-col vs-w="12" style="padding: 5px; font-size: 15px;">
                            <el-input placeholder="" v-model="elementChoisi.bl" class="custom-input1">
                              <template slot="prepend"><span class="custom-input2">NUM BL</span></template>
                            </el-input>
                          </vs-col>
                          <vs-col vs-w="12" style="padding: 5px; font-size: 15px;">
                            <el-input placeholder="" v-model="elementChoisi.n_declaration" class="custom-input1">
                              <template slot="prepend"><span class="custom-input2">DECLARATION</span></template>
                            </el-input>
                          </vs-col>
                          <vs-col vs-w="12" style="padding: 5px; font-size: 15px;">
                            <el-input placeholder="" v-model="elementChoisi.nombre" class="custom-input1">
                              <template slot="prepend"><span class="custom-input2">NBR TCS</span> </template>
                            </el-input>
                          </vs-col>
                          <vs-col vs-w="12" style="padding: 5px; font-size: 15px;">
                            <el-input placeholder="" v-model="elementChoisi.badt" class="custom-input1">
                              <template slot="prepend"><span class="custom-input2">BADT</span></template>
                            </el-input>
                          </vs-col>
                          <vs-col vs-w="12" style="padding: 5px; font-size: 15px;">
                            <el-input placeholder="" v-model="elementChoisi.observation" class="custom-input1">
                              <template slot="prepend"><span class="custom-input2">CONTACT</span></template>
                            </el-input>
                          </vs-col>
                        </vs-row>
                      </div>
    
                    </div>
                  </section>
              </vue-html2pdf>
             
                    
            </center>
                  
           
          </vs-popup>
        </div>
       
    </div>
  </template>
  
  <script>
  import { isMobile } from 'mobile-device-detect';
  import * as XLSX from "xlsx";
  import VueHtml2pdf from 'vue-html2pdf'
  import html2pdf from "html2pdf.js";
  import jsPDF from 'jspdf'
  import html2canvas from 'html2canvas';
  import axios from 'axios';
  import NavBar from '@/components/bennes/Navbar.vue'
  export default {
    name: 'HelloWorld',
    components: {
      NavBar,
      VueHtml2pdf
    },
    computed: {
      users() {
        return this.$store.state.users;
          },
        },
    data() {
      return {
        form: {
          client: '',
          produit: '',
          bon: '',
          dte_voyage: '',
          camion: '',
          chauffeur: '',
          poids: '',
          chargement: '',
          dechargement: '',
          entreprise: 1,
          id_bon: 0,
          },
        popupActivo4:false,
        choixMob: 'import',
        isMobiles : isMobile,
        search: '',
        chargeeir: false,
        eirdata: {
          client: '',
          eir: ''
        },
        eirdatas: {
          conteneur: '',
          eir: ''
        },
        chargeFichier : 0,
        chargeFichier1 : 0,
        chargeFichier2 : 0,
        chargeFichier3 : 0,
        container: '',
        refFacture: '',
        dossierRef: '',
        facturation: false,
        counter: false,
        prixUnit: 0,
        dateSearch: [
          new Date().toISOString().substr(0, 10),
          new Date().toISOString().substr(0, 10)
          ],
        popupActivo2:false,
        popupActivo22:false,
        popupActivo3:false,
        activePrompt9:false,
        select1:3,
        options1:[
          {text:'IT',value:0},
          {text:'Blade Runner',value:2},
          {text:'Thor Ragnarok',value:3},
        ],
        formDate: {
          dateBen: ''
        },
        checkTrue: true,
        donneesClient: null,
        donneesCliProd: null,
        checkFalse: false,
        totalVoyages:0,
        donnees: null,
        don_Clients: [{"client":"0","produit":"0","total_enregistrements":"0","total_poids":"0"}],
        don_Clients2: [{"client":"0","produit":"0","total_enregistrements":"0","total_poids":"0"}],
        don_Camion: null,
        donneesStats: {
          dn_carb: [{"total":"0","litre":"0","prix":"0"}],
          dn_dep: [{"total":"0","prix":"0"}],
          dn_voyages: [{"chauffeur":"","client":"","dossier":"0","id_ben":"0","lieu_chargement":"0","lieu_dechargement":"","n_bl":"","n_matricule":"","observation":"0","par":"0","poids":"0","prix":"0","produit":"0"}],
          dn_clients: [{"client":"0","produit":"0","total_enregistrements":"0","total_poids":"0"}],
          nb_carb: 0,
          nb_dep: 0,
          nb_voyages: 0,
          tableau: [{"camion":"0","chauffeur":"0","date_carb":"0","details":"0","id_camion":"0","id_carburant":"0","imatriculation":"0","litre":"0","marque":"0","nombre_de_bennes":"0","prix":"0"}],
        },
        donneesStatsMois: null,
        donneesStatsSemaine: null,
        donneesArmateurs: null,
        donneesSheet: null,
        elementChoisi: {
          date_recept: ''
        },
        deletebon: 0,
        donneesZones: null,
        donneesCamion: null,
        ClientChoisi: {
          client: '',
          produit: '',
          total_enregistrements: '',
          total_poids: ''
        },
        donneesChauf: null,
        donneesDossiers: null,
        donneesDossiersBadt: null,
        donneesDossiersNbadt: null,
        deadline3: Date.now() + (new Date().setHours(17, 59, 59) - Date.now()),
        like: true,
          value1: '',
          value2: '',
          title: 'Growth this year',
        activeName: 'first',
        chartData: {
          labels: [ 'Janvier', 'Fevrier', 'Mars', 'Avril', 'Mais', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre' ],
          datasets: [ { data: [40, 20, 12, 33, 10, 3, 45, 41, 26, 11, 19, 26] } ]
        },
        chartOptions: {
          responsive: true
        },
          clients: [{
            value: 'LEB',
            label: 'LEB'
          }],
          produits: [{
            value: 'BAUXITE',
            label: 'BAUXITE'
          }],
          type_cont: [{
            value: "20 'OPEN",
            label: "20 'OPEN"
          }, {
            value: "40 'OPEN",
            label: "40 'OPEN"
          }, {
            value: "20 'FLAT",
            label: "20 'FLAT"
          },{
            value: "40 'FLAT",
            label: "40 'FLAT"
          }, {
            value: "20 'DRY",
            label: "20 'DRY"
          }, {
            value: "40 'DRY",
            label: "40 'DRY"
          }, {
            value: "20 'FRIGO",
            label: "20 'FRIGO"
          }, {
            value: "40 'FRIGO",
            label: "40 'FRIGO"
          }],
          armateur: [{
            value: "Evergreen",
            label: "Evergreen"
          }, {
            value: "Maersk",
            label: "Maersk"
          }, {
            value: "Safmarine",
            label: "Safmarine"
          },{
            value: "Cosco",
            label: "Cosco"
          }, {
            value: "One",
            label: "One"
          }, {
            value: "PIL",
            label: "PIL"
          }, {
            value: "Oma",
            label: "Oma"
          }, {
            value: "Medlog",
            label: "Medlog"
          }],
        tableData: [{
            date: '2016-05-03',
            name: 'TRAORE Oumar',
            address: 'No. 189, Grove St, Los Angeles',
            st: 'En cours'
          }, {
            date: '2016-05-02',
            name: 'SAKONE Oumar',
            address: 'No. 189, Grove St, Los Angeles',
            st: 'Validé'
          }, {
            date: '2016-05-04',
            name: 'SAKONE Oumar',
            address: 'No. 189, Grove St, Los Angeles',
            st: 'En cours'
          }, {
            date: '2016-05-01',
            name: 'SAKONE Oumar',
            address: 'No. 189, Grove St, Los Angeles',
            st: 'Annulé'
          }, {
            date: '2016-05-08',
            name: 'SAKONE Oumar',
            address: 'No. 189, Grove St, Los Angeles',
            st: 'Validé'
          }, {
            date: '2016-05-06',
            name: 'SAKONE Oumar',
            address: 'No. 189, Grove St, Los Angeles',
            st: 'En cours'
          }, {
            date: '2016-05-07',
            name: 'SAKONE Oumar',
            address: 'No. 189, Grove St, Los Angeles',
            st: 'En cours'
          }],
      }
    },
    methods: {
      isMobile() {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true
        } else {
          return false
        }
      },

      Modif(data) {
        this.form = data;
        this.popupActivo22 = true;
        console.log(data);
      },
  
      choixMoba(a) {
        this.choixMob = a;
      },
  
          printYear: function () {
              return new Date().getFullYear() + 
              '' + new Date().getMonth()+ 
              '' + new Date().getDate()+
              '' + new Date().getHours()+
              '' + new Date().getMinutes()+
              '' + new Date().getMilliseconds()+
              '' + new Date().getSeconds();
        },
  
      choixPeriode(a) {
        this.periode = a;
        console.log(this.periode);
      },
  
      dateCherche() {
        const year = this.dateSearch[0].getFullYear();
            const month = String(this.dateSearch[0].getMonth() + 1).padStart(2, '0');
            const day = String(this.dateSearch[0].getDate()).padStart(2, '0');
  
            const year2 = this.dateSearch[1].getFullYear();
            const month2 = String(this.dateSearch[1].getMonth() + 1).padStart(2, '0');
            const day2 = String(this.dateSearch[1].getDate()).padStart(2, '0');
  
            let dte_db = `${year}-${month}-${day}`;
            let dte_fn = `${year2}-${month2}-${day2}`;
  
        this.$router.push({ name: 'Conteneur_recherche', params: { debut: dte_db, fin: dte_fn, } });
      },
  
      Facturer(id) {
        this.dossierRef = id;
        this.facturation = true;
      },
  
      Facturons () {
              this.form.dossier = this.dossierRef;
              this.form.ref = this.refFacture;
              axios.put('https://socogetra.net/appi_socogetra/soco_ordre_facture.php', this.form)
              .then((response) => {
                  console.log(response);
                  this.listeDossiers();
                  this.popupActivo2 = false,
                  this.$vs.loading.close();
                  this.$notify.success({
                  title: 'Dossier Facturé !',
                  showClose: false
                  });
              })
              .catch(function (error) {
                  console.log(error);
                  this.$vs.loading.close()
              });
      },
  
      listeDossiers () {
              this.$vs.loading({
              type:'point',
              })
              axios.get('https://sygestra.com/appi_sygestra/ajout_bon.php?ent='+this.users.n_ent+'&timestamp=' + new Date().getTime())
              .then((response) => {
                this.donneesDossiers = response.data.data;
                this.donneesCamion = response.data.camion;
                this.donneesChauf = response.data.chauf;
                this.$vs.loading.close()
              }, (error) => {
                console.log(error);
                this.$vs.loading.close()
              });
          },
  
        ExportExcel(type, fn, dl) {
            var elt = this.$refs.exportable_table;
            var wb = XLSX.utils.table_to_book(elt, {sheet:"Sheet JS"});
            return dl ?
              XLSX.write(wb, {bookType:type, bookSST:true, type: 'base64'}) :
            XLSX.writeFile(wb, fn || ((this.name + '.'|| 'SheetJSTableExport.') + (type || 'xlsx')));
          },
  
        searchDate () {
            
            this.$vs.loading({
            type:'point',
            })
            const year = this.dateSearch[0].getFullYear();
            const month = String(this.dateSearch[0].getMonth() + 1).padStart(2, '0');
            const day = String(this.dateSearch[0].getDate()).padStart(2, '0');
  
            const year2 = this.dateSearch[1].getFullYear();
            const month2 = String(this.dateSearch[1].getMonth() + 1).padStart(2, '0');
            const day2 = String(this.dateSearch[1].getDate()).padStart(2, '0');
  
            let dte_db = `${year}-${month}-${day}`;
            let dte_fn = `${year2}-${month2}-${day2}`;
  
            fetch('https://socogetra.net/appi_sococit/albakoos_produits_clients_date.php?date_db=' + dte_db + '&date_fn=' + dte_fn + '&timestamp=' + new Date().getTime())
            .then(response => response.json())
            .then(data => {
              this.donneesClient = data.data;
              this.$vs.loading.close()
            });
  
            axios
                .get('https://socogetra.net/appi_sococit/albakoos_stats_date.php?date_db=' + dte_db + '&date_fn=' + dte_fn + '&timestamp=' + new Date().getTime())
                .then(response => (this.don_Clients = response.data.dn_clients))
  
        },
  
        listeDossier () {
            this.$vs.loading({
            type:'point',
            })
            axios.get('https://socogetra.net/appi_sococit/albakoos_dossier_sbtl.php?timestamp=' + new Date().getTime())
            .then((response) => {
              this.donnees = response.data.data;
              this.$vs.loading.close()
            }, (error) => {
              console.log(error);
              this.$vs.loading.close()
            });
        },
  
        formatMoney(value) {
          const numberValue = parseFloat(value.replace(/ /g, '').replace(/,/g, '.'));
          if (isNaN(numberValue)) {
            return '';
          }
          return numberValue.toLocaleString('fr-FR', {
            style: 'currency',
            currency: 'XOF'
          });
        },
  
        listeZones () {
              this.$vs.loading({
              type:'point',
              })
              axios.get('https://socogetra.net/appi_sococit/albakoos_zones.php?timestamp=' + new Date().getTime())
              .then((response) => {
                this.donneesZones = response.data.data;
                this.$vs.loading.close()
              }, (error) => {
                console.log(error);
                this.$vs.loading.close()
              });
          },
  
          showClient(client) {
            this.ClientChoisi = client;
            console.log(this.ClientChoisi);
            this.popupActivo2 = true
          },
  
          generateReport () {
            this.$refs.html2Pdf.generatePdf()
            this.popupActivo2 = false;
            this.activePrompt9 = true;
          },
  
          exportToPDF() {
            html2pdf(document.getElementById("html-to-pdf"), {
              margin: 1,
              filename: "i-was-html.pdf",
            });
          },
  
          listeChauffeurs () {
              this.$vs.loading({
              type:'point',
              })
              axios.get('https://socogetra.net/appi_sococit/albakoos_chauffeurs.php?timestamp=' + new Date().getTime())
              .then((response) => {
                this.donneesChauf = response.data.data;
                this.$vs.loading.close()
              }, (error) => {
                console.log(error);
                this.$vs.loading.close()
              });
          },

          verif() {
            if(this.users.conteneur == "KO") {
                this.$router.push({ path: '/dashboard' });
            }
          },
  
          Borderau(element) {
            this.elementChoisi = element;
            this.activePrompt9 = true;
            this.generateReport();
          },
  
          generatePDF () {
            var doc = new jsPDF();
            
            // Récupérer l'élément HTML que vous voulez convertir en PDF
            const element = document.getElementById('html-to-pdf')
  
            // Convertir l'élément HTML en Canvas
            html2canvas(element).then(canvas => {
              // Ajouter le Canvas au document PDF avec une résolution de 300 DPI
              const imgData = canvas.toDataURL('image/png', 2.7);
              doc.addImage(imgData, 'PNG', 10, 10);
              
              // Sauvegarder le document PDF
              doc.save('example.pdf')
            })
          },
  
         // generatePDF () {
          //  var doc = new jsPDF();
  
            // Récupérer l'élément HTML que vous voulez convertir en PDF
          //  const element = document.getElementById('html-to-pdf')
  
            // Convertir l'élément HTML en Canvas
          //  html2canvas(element).then(canvas => {
              // Ajouter le Canvas au document PDF
           //   const imgData = canvas.toDataURL('image/png')
            //  doc.addImage(imgData, 'PNG', 10, 10)
  
              // Sauvegarder le document PDF
           //   doc.save('example.pdf')
          //  })
         // },
  
          listeCamion () {
              this.$vs.loading({
              type:'point',
              })
              axios.get('https://socogetra.net/appi_sococit/albakoos_camion.php?timestamp=' + new Date().getTime())
              .then((response) => {
                this.donneesCamion = response.data.data;
                this.$vs.loading.close()
              }, (error) => {
                console.log(error);
                this.$vs.loading.close()
              });
          },
  
          sheet () {
              this.$vs.loading({
              type:'point',
              })
              axios.get('https://docs.google.com/spreadsheets/d/1hsitLRk83_0P5yJQUC6A9dNB8OQ2HfOI-9BGfy0VF3g/edit#gid=0')
              .then((response) => {
                this.donneesSheet = response.data.data;
                this.$vs.loading.close()
              }, (error) => {
                console.log(error);
                this.$vs.loading.close()
              });
          },
  
          handleSelectChange(choix) {
            console.log(choix);
            const oumarItem = this.donneesZones.find(item => item.zone_livraison === choix);
            this.form.zone_facturation = oumarItem ? oumarItem.zone_facturation : null;
          },

          supBon(id, c, b) {
              this.deletebon = id;
              this.$vs.dialog({
              color:'danger',
              title: `Suppression`,
              text: 'Êtes vous sûre de vouloir supprimer ? ' + b + ' du camion ' + c,
              accept:this.onDelete
            })
          },

          onDelete() {
                axios.delete('https://sygestra.com/appi_sygestra/ajout_bon.php?id='+this.deletebon)
                .then((response) => {
                    console.log(response);
                    this.$vs.loading.close();
                    this.popupActivo2 = false;
                    this.listeDossiers();
                    this.$notify.success({
                    title: 'Bon !',
                    message: 'Supprimé avec Succès',
                    showClose: false
                    });
                })
                .catch(function (error) {
                    console.log(error);
                    this.$vs.loading.close()
                });
            },
  
        onSubmit() {
          this.form.entreprise = this.users.n_ent;
              fetch('https://sygestra.com/appi_sygestra/ajout_bon.php', {
                method: 'POST',
                body: JSON.stringify(this.form),
                headers: {
                  'Content-Type': 'application/json'
                }
              })
              .then(response => {
                console.log(response);
                this.listeDossiers();
              })
              .then(data => {
                console.log(data);
                this.listeDossiers();
                this.popupActivo2 = false;
                this.$vs.loading.close();
                this.$notify.success({
                  title: 'Bon OK!',
                  message: 'Bon Ajouté',
                  showClose: false
                });
              })
              .catch(error => {
                console.log(error);
                this.$vs.loading.close();
              });
          },

          onUpdate() {
          this.form.entreprise = this.users.n_ent;
              fetch('https://sygestra.com/appi_sygestra/ajout_bon.php', {
                method: 'PUT',
                body: JSON.stringify(this.form),
                headers: {
                  'Content-Type': 'application/json'
                }
              })
              .then(response => {
                console.log(response);
                this.listeDossiers();
                this.popupActivo22 = false;
              })
              .then(data => {
                console.log(data);
                this.listeDossiers();
                this.$vs.loading.close();
                this.$notify.success({
                  title: 'Bon OK!',
                  message: 'Bon Ajouté',
                  showClose: false
                });
              })
              .catch(error => {
                console.log(error);
                this.$vs.loading.close();
              });
          },
  
        clickFn() {
          this.$refs.statistic.suspend(this.stop);
          this.stop = !this.stop;
        },
        handleClick(tab, event) {
          console.log(tab, event);
        },
        hilarity() {
          this.$notify({
            title: 'TRAVAIL DU JOUR TERMINE',
            message: "C'est bon pour aujourd'hui, vous pouvez entrez à la maison",
            duration: 0,
          });
        },
  
  
          getData() {
            const token = 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjA1REVBNjlBOUIxODE4RjI3RkIxNUQ2QzQ2QkI0NEU0IiwidHlwIjoiYXQrand0In0.eyJpc3MiOiJodHRwczovL2FjY291bnQubmF2aXMuY29tIiwibmJmIjoxNjg0MTAzMjY3LCJpYXQiOjE2ODQxMDMyNjcsImV4cCI6MTY4NDExMDQ2NywiYXVkIjpbImNvbXBhbnljbG91ZC1wdWJsaWNhcGkiLCJodHRwczovL2FjY291bnQubmF2aXMuY29tL3Jlc291cmNlcyJdLCJzY29wZSI6WyJjb21wYW55Y2xvdWQtcHVibGljYXBpIiwib2ZmbGluZV9hY2Nlc3MiXSwiYW1yIjpbInB3ZCJdLCJjbGllbnRfaWQiOiJTbWFydEFjY2Vzcy1BcHAiLCJzdWIiOiI4NDVmNTIzYS0xMTBjLTQ4NjQtODVkNC1hYzE3NzlkN2YxZDAiLCJhdXRoX3RpbWUiOjE2ODQxMDMyNjYsImlkcCI6ImxvY2FsIiwiZW1haWwiOiJzYWtvbmVAc29jb2dldHJhLm5ldCIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2VtYWlsYWRkcmVzcyI6InNha29uZUBzb2NvZ2V0cmEubmV0Iiwiam9pbmRhdGUiOiIwNS8wMy8yMDIzIDEwOjI1IiwicGljdHVyZSI6Imh0dHBzOi8vaW1hZ2VzLmFjY291bnQubmF2aXMuY29tL2VmZmVjNmUyLTIzNWQtNDNjMy05YmVmLTNlNTBkZDM0MmEwMS8xMDAucG5nIiwiZ2l2ZW5fbmFtZSI6IlNBS09ORSIsImZhbWlseV9uYW1lIjoiT3VtYXIiLCJzaWQiOiJFMkE2QzU4RkVDQzM0MDlFNjk4NDUzMURGNjc4RUMxQyJ9.rPA25RdckhXhl5sWBjVhI-C-8rIu3dX-w9zP2rk1Kwfpw8ED9LYV3J8d2sQknShx8vGTaZwSCiQLC55KNM1mi1b603uH8QEqz-Pb1Ql-wNWv_5NHIDdEG7d45G2KEX_CNjKBnNHS6agoatJbbjHQymdxmaudwQRqDJo9-mDXsG_ZUwD-vjYFQKvFqBzVJricJZiwrDAZXXIp9Yc2AvOVFsQs3DHXHk8GmY98q3-qt_Dfbr1UiJM-iejYzCO-dAa-YX2D78XK0UG9ejMcwKB1jBXpt0TgLMSHKIKcQSGVX5qPj_IHBB_dFgf3nNmrCz7mgoe4p1BCWHuItfk2iF7ccg'
            axios.get('https://api-smartaccess.cotedivoireterminal.com/smartaccess/api/gateway/vessel/visits', {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'accountIdentifier': 'takt',
                'accountType': 'Haulier',
                'partnerAccountIdentifier': 'cit',
                'appIdentifier': 'smrtaccess',
                'Authorization': 'Bearer '+token,
              }
            })
            .then(response => {
              console.log(response.data);
            })
            .catch(error => {
              console.error(error);
            });
          }
      },
  
      mounted() {
        this.listeDossiers();
  
      },
      
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .el-input__inner::placeholder {
      color: #201f1f;
  }
  .custom-input1 {
    font-size: 24px;
  }
  .custom-input1 .el-input__inner {
    font-size: 24px;
    padding-top: 18px !important; /* Ajuste l'espacement interne pour descendre le texte */
  }
  .custom-input2 {
    font-size: 19px;
  }
  </style>
  