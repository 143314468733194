<template>
    <div class="about" style="width: 100%; height: 100vh;">
      <div class="outer">
        <div class="middle">
          <div class="inner">
            <div style="padding: 10px; border-radius: 30px; background: white">
              <img src="../assets/logo_sys.png" height="65">
            </div>
            <vs-card class="login" style="width: 800px; margin: 0 auto; height: 200px;">
                <h2>Bonjour Monsieur {{users.nom.toUpperCase()}} {{users.prenom}}</h2>
                <vs-divider/>
                <router-link to="/dashboard_conteneur"> <button class="button-dashe" v-show="users.conteneur == 'OK'">Conteneur</button> </router-link>
                <router-link to="/dashboard_benne"><button class="button-dashe" v-show="users.benne == 'OK'">Bennes</button></router-link>
                <router-link to="/dashboard_citerne"><button class="button-dashe" v-show="users.citerne == 'OK'">Citernes</button></router-link>
            </vs-card>
            <vs-card class="login" style="width: 800px; margin: 0 auto; height: 130px; background: #271d79">
              <button class="button-dash" v-show="users.administration == 'OK'">Administration</button>
              <button class="button-dash" v-show="users.comptabilite == 'OK'">Comptabilite</button>
              <router-link to="/facturation_conteneur"><button class="button-dash" v-show="users.facturation == 'OK'">Facturation</button></router-link>
              <button class="button-dash" v-show="users.cloud == 'OK'">Cloud</button>
            </vs-card>
          </div>
        </div>
      </div>
      
    </div>
  </template>
  <script>
  import axios from 'axios';
  export default {
    name: 'HelloWorld',
    computed: {
    users() {
      return this.$store.state.users;
        }
      },
    data() {
      return {
        erreur:false,
        mdp: "",
        user: "",
        connexionMessage: "",
        ip: "",
        vip: "",
      }
    },
    methods: {
          onEnter() {
            alert('lala');
          },
           getdata() {
              this.$vs.loading()
              let formData = new FormData();
              formData.append('user', this.user);
              formData.append('mdp', this.mdp);
  
              axios.post('https://sogecit.net/appi_socogetra/db_login_verif.php', formData,)
              .then((response) => {
                this.verif(response);
                this.$vs.loading.close()
              }, (error) => {
                console.log(error);
              });
            },
  
            verif(data) {
              let donnees = data.data.data[0];
              this.connexionMessage = data.data.mes
              if(data.data.valide == 1) {
                if(donnees.lieu == 2) {
                  this.$store.commit('Connexion', donnees);
                  this.$router.push({ path: '/' });
                }
                if(donnees.lieu == 1 && this.ip == this.vip) {
                    this.$store.commit('Connexion', donnees);
                    this.$router.push({ path: '/' });
                  } else {
                    this.connexionMessage = 'Vous devez être au bureau pour vous connecter';
                    this.erreur=true;
                  }
              }
              if(data.data.valide == 2) {
                  this.erreur=true;
                }
            },
  
            Ip() {
            fetch('https://api.ipify.org?format=json')
            .then(x => x.json())
            .then(({ ip }) => {
                this.ip = ip;
                console.log(this.ip);
            });
          },
      },
      mounted() {
        this.Ip();
      }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .outer {
    display: table;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  
  .middle {
    display: table-cell;
    vertical-align: middle;
  }
  
  .inner {
    margin-left: auto;
    margin-right: auto;
    width: 800px;
    /* Whatever width you want */
  }
  .button-dash {
    width: 180px;
    border-radius: 15px;
    height: 70px;
    border: none;
    color: white;
    padding: 16px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 19px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
  }

  .button-dash {
    background-color: rgba(128, 128, 128, 0.37); 
    color: white; 
    border: 2px solid white;
  }

  .button-dash:hover {
    border-radius: 15px;
    background-color: white;
    color: black;
  }
  .button-dashe {
    width: 200px;
    border-radius: 15px;
    height: 70px;
    border: none;
    color: white;
    padding: 16px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 19px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
  }
  
  .button-dashe {
    background-color: white; 
    color: black; 
    border: 2px solid #04AA6D;
  }
  
  .button-dashe:hover {
    border-radius: 15px;
    background-color: #04AA6D;
    color: white;
  }
  </style>
  